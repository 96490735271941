<template>
  <div class="row">
    <div class="col-12 col-md-3 col-lg-2 col-xl-2 m-auto">
      <div class="rect-img-container">
        <img
          class="rect-img profile-img"
          :src="
            imgDataUrl
              ? imgDataUrl
              : user
              ? userAvatarUrlByid(user.id)
              : '/img/diva_avatar.png'
          "
          @error="errorAvatar"
        />
      </div>
      <AvatarUpload
        field="img"
        @crop-success="cropSuccess"
        v-model="show"
        :width="200"
        :height="200"
        noSquare
        langType="en"
        img-format="png"
      ></AvatarUpload>
    </div>
    <div class="col-12 col-md-9 col-lg-10 col-xl-10 m-auto">
      <base-button type="primary" @click="toggleShow">
        {{ getTrans('messages.image_upload') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import AvatarUpload from 'vue-image-crop-upload';
import Errors from '@/mixins/Errors';
import { mapGetters } from 'vuex';

export default {
  name: 'user-avatar',
  components: {
    AvatarUpload,
  },
  emits: ['update'],
  mixins: [Errors],
  props: {
    user: Object,
  },
  data() {
    return {
      file: '',
      pic: '',
      defaultImg: '/public/img/diva-avatar.png',
      errors: {},
      temp: [],
      show: false,
      imgDataUrl: '',
    };
  },
  computed: {
    ...mapGetters('User', {
      userAvatarUrlByid: 'userAvatarUrlByid',
    }),
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.$emit('update', {
        id: this.user.id,
        profile_pic: this.convertToFile(this.imgDataUrl),
      });
    },

    convertToFile(input) {
      // eslint-disable-next-line
      const fileRe = /^data:([^\/;]+\/[^\/;]+);base64,(.*)$/;
      let info = fileRe.exec(input);
      if (!info) {
        return null;
      }

      let mimetype = info[1];
      let contents = info[2];
      let filetype = mimetype.split('/')[1];
      let dataCodes = atob(contents)
        .split('')
        .map((c) => c.charCodeAt(0));

      return new File([new Uint8Array(dataCodes)], 'cropped.' + filetype, {
        type: mimetype,
      });
    },
  },
};
</script>

<style scoped>
.profile-img {
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  padding: 2px;
}
.rect-img-container {
  position: relative;
}

.rect-img-container::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.rect-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
